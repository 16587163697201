import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useSelector } from 'react-redux';
import { useFetchVocab } from '../hooks/firebase/useFetchVocab';
import { useAnswerChecker } from '../hooks/useTrainingAnswerChecker';
import { useChat } from '../hooks/useChat';
import { useTrainingVocabUpdate } from '../hooks/useTrainingVocabUpdate';
import useOpenAiTextToSpeech from '../hooks/useOpenAiTextToSpeech';
import { useUpdateScore } from '../hooks/useUpdateScore'; // Nur updateScore, KEIN useScoreUpdate!
import { useVocabErrorTip } from '../hooks/useErrorTip';
import useHasError from '../hooks/useHasError';
import ChatUI from './TrainingChatUI';
import { TagFilter } from './TagFilter';
import VocabDisplay from './VocabDisplay'
import { 
    Container, 
    Box, 
    Typography, 
    Switch, 
    FormControlLabel, 
    LinearProgress, 
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    CircularProgress,
    Card, 
    CardContent,
    Select,
    MenuItem,
    Divider,
    Tooltip
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { Loader2 } from 'lucide-react';
import VocabInput from './VocabTrainingInput';
import { triggerLargerConfetti, triggerConfettiRain, triggerLongLastingConfetti, triggerConfettiExplosion } from '../hooks/useConfettiEffect';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const debug = false;

const VocabProgress = ({ progress }) => {
    const totalAttempts = progress.attempts;
    const totalVocabs = progress.totalVocabs;
    const correctPercentage = totalAttempts > 0 
      ? ((totalVocabs / totalAttempts) * 100).toFixed(2) 
      : '0.00';

    return (
        <Card elevation={3}>
            <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                    <CheckCircleOutlineIcon color="success" fontSize="large" />
                    <Typography variant="h5" component="div" ml={1}>
                        Gratulation!
                    </Typography>
                </Box>
                <Typography variant="body1" gutterBottom>
                    Du hast alle Vokabeln für heute gelernt.
                </Typography>
                <Box my={2}>
                    <LinearProgress 
                        variant="determinate" 
                        value={parseFloat(correctPercentage)} 
                        color="primary"
                    />
                </Box>
                <Typography variant="body2" color="text.secondary">
                    Korrekte Eingaben: {correctPercentage}% ({totalVocabs} von {totalAttempts || 0})
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Falsche Eingaben: {totalAttempts - totalVocabs}
                </Typography>
            </CardContent>
        </Card>
    );
};

const NoVocabsForToday = ({ user, language }) => {
    return (
        <Card elevation={3}>
            <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <CheckCircleOutlineIcon color="success" style={{ fontSize: 60, marginBottom: 16 }} />
                    <Typography variant="h5" gutterBottom>
                        Alle Vokabeln gelernt!
                    </Typography>
                    <Typography variant="body1">
                        Für heute sind keine weiteren Vokabeln zu trainieren.
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default function TrainingPage({ userName }) {
    const [user] = useAuthState(auth);
    const language = useSelector((state) => state.language);
    const { vocabs, loading, error, fetchVocabs } = useFetchVocab();
    const [hasFetched, setHasFetched] = useState(false);
    const [isPlayTextEnabled, setIsPlayTextEnabled] = useState(true);
    const [isReverseOrder, setIsReverseOrder] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(true);
    const [scoreAnimation, setScoreAnimation] = useState(null);
    const [errorAnimation, setErrorAnimation] = useState(false);
    const [isErrorTipOpen, setIsErrorTipOpen] = useState(false);
    const [errorTipLoading, setErrorTipLoading] = useState(false);
    const [vocabLimit, setVocabLimit] = useState('all');
    const [activeVocabs, setActiveVocabs] = useState([]);
    const [currentVocabId, setCurrentVocabId] = useState(null);
    const [tagFilters, setTagFilters] = useState({});

    // Training-Logik
    const { checkAnswer, resetVocabs, currentVocab, progress, moveCurrentVocabToEnd } = useAnswerChecker([], isReverseOrder);
    
    // Chat-Logik
    const { messages, messagesContainerRef, addMessage } = useChat();
    
    // Vokabel-Update
    const { updateTrainingVocab } = useTrainingVocabUpdate();

    // TTS
    const { playText } = useOpenAiTextToSpeech();

    // Scores werden über Firestore erhöht
    const { updateScore } = useUpdateScore(user?.uid);

    // Error-Tip
    const { tip, getErrorTip } = useVocabErrorTip();
    const hasError = useHasError(currentVocab?.id, isReverseOrder);

    // Sound-Effekte
    const correctSoundRef = useRef(null);
    const incorrectSoundRef = useRef(null);

    // Für Konfetti
    const [confettiTarget, setConfettiTarget] = useState(null);
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
    const [currentConfettiEffect, setCurrentConfettiEffect] = useState(null);

    const playSound = useCallback((audioRef) => {
      if (audioRef.current) {
          audioRef.current.currentTime = 0;
          audioRef.current.play().catch(error => {
              console.error("Error playing sound:", error);
          });
      }
    }, []);

    const fetchVocabsCallback = useCallback(() => {
      if (user && language && !hasFetched) {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          tomorrow.setHours(0, 0, 0, 0);
          const tomorrowString = tomorrow.toISOString();
          
          if (debug) console.log('Fetching vocabs with filter nextTraining <= tomorrow');
          
          fetchVocabs(
              { 
                  languageId: language.id,
                  nextTraining: ['<=', tomorrowString]
              },
              ['id', 'foreign', 'german', 'nextTraining', 'knownCounter', 'additionalInfo', 'languageId', 'errors', 'attributes']
          );
          setHasFetched(true);
      }
    }, [user, language, fetchVocabs, hasFetched]);

    useEffect(() => {
      if (debug) console.log('Effect. user:', user?.uid, 'language:', language?.id, 'hasFetched:', hasFetched);
      fetchVocabsCallback();
    }, [fetchVocabsCallback]);

    // Beim Sprachenwechsel oder wenn Komponente neu geladen -> reset
    useEffect(() => {
      setHasFetched(false);
      resetVocabs([]);
    }, [language, resetVocabs]);

    useEffect(() => {
        correctSoundRef.current = new Audio('/assets/correct-sound.mp3');
        incorrectSoundRef.current = new Audio('/assets/incorrect-sound.mp3');

        correctSoundRef.current.load();
        incorrectSoundRef.current.load();

        return () => {
            [correctSoundRef, incorrectSoundRef].forEach(ref => {
                if (ref.current) {
                    ref.current.pause();
                    ref.current = null;
                }
            });
        };
    }, []);

    const setRandomConfettiTarget = useCallback(() => {
        const newTarget = Math.floor(Math.random() * 5) + 4; // 4 bis 8
        setConfettiTarget(newTarget);
        if (debug) console.log('New confetti target set:', newTarget);
    }, []);

    const setRandomConfettiEffect = useCallback(() => {
        const effects = [
          triggerLargerConfetti, 
          triggerConfettiRain, 
          triggerLongLastingConfetti, 
          triggerConfettiExplosion
        ];
        const randomEffect = effects[Math.floor(Math.random() * effects.length)];
        setCurrentConfettiEffect(() => randomEffect);
        if (debug) console.log('New confetti effect set');
    }, []);

    useEffect(() => {
        if (vocabs.length > 0) {
            let filtered = [...vocabs];

            // Tag-Filter
            const hasActiveTagFilters = Object.values(tagFilters).some(values => 
                Object.values(values).some(isActive => isActive)
            );
            if (hasActiveTagFilters) {
                filtered = filtered.filter(vocab => {
                    return Object.entries(tagFilters).every(([key, values]) => {
                        const activeValues = Object.entries(values)
                            .filter(([_, isActive]) => isActive)
                            .map(([value]) => value);
                        if (activeValues.length === 0) return true;
                        return vocab.attributes && 
                               vocab.attributes[key] && 
                               activeValues.includes(vocab.attributes[key]);
                    });
                });
            }

            // Shuffle
            filtered.sort(() => Math.random() - 0.5);

            // Limit
            if (vocabLimit !== 'all') {
                filtered = filtered.slice(0, parseInt(vocabLimit));
            }

            setActiveVocabs(filtered);
            resetVocabs(filtered);
            setRandomConfettiTarget();
            setRandomConfettiEffect();
            setCurrentVocabId(filtered[0]?.id || null);
        }
    }, [
      vocabs, 
      vocabLimit, 
      resetVocabs, 
      setRandomConfettiTarget, 
      setRandomConfettiEffect, 
      tagFilters
    ]);

    const handleSwitchChange = (event) => {
        setIsPlayTextEnabled(event.target.checked);
    };

    const handleVocabLimitChange = (event) => {
        setVocabLimit(event.target.value);
    };

    const triggerConfetti = useCallback(() => {
        if (currentConfettiEffect) {
            currentConfettiEffect();
            if (debug) console.log('Confetti effect triggered');
        }
        setRandomConfettiTarget();
        setRandomConfettiEffect();
    }, [currentConfettiEffect, setRandomConfettiTarget, setRandomConfettiEffect]);

    const handleInputSubmit = useCallback(async (inputValue) => {
      const result = await checkAnswer(inputValue, isReverseOrder);

      if (result !== null) {
        const userMessage = isReverseOrder 
            ? `${currentVocab.foreign}: ${inputValue}` 
            : `${currentVocab.german}: ${inputValue}`;
        addMessage({ type: 'user', text: userMessage });
        
        if (result.isCorrect) {
            // Korrekte Antwort
            playSound(correctSoundRef);

            setScoreAnimation(`+${currentVocab.knownCounter + 1}`);
            setTimeout(() => setScoreAnimation(null), 2000);

            try {
                const updatedVocab = await updateTrainingVocab(currentVocab, true);
                if (debug) console.log('Vocab updated:', updatedVocab);

                const scoreIncrease = currentVocab.knownCounter + 1;
                await updateScore(scoreIncrease);  // erhöht Firestore dayScore & totalScore

            } catch (error) {
                if (debug) console.error('Error updating vocab or score:', error);
            }

            const newCorrectAnswersCount = correctAnswersCount + 1;
            setCorrectAnswersCount(newCorrectAnswersCount);
            if (newCorrectAnswersCount === confettiTarget) {
                triggerConfetti();
                setCorrectAnswersCount(0);
            }
        } else {
            // Falsche Antwort
            setErrorAnimation(true);
            setTimeout(() => setErrorAnimation(false), 2000);

            playSound(incorrectSoundRef);
            
            if (isPlayTextEnabled) {
                try {
                    const correctionMessageWithoutAdditionalInfo = isReverseOrder 
                        ? `Leider nein, das Wort "${currentVocab.foreign}" heißt auf Deutsch "${currentVocab.german}".` 
                        : `Leider nein, das deutsche Wort "${currentVocab.german}" heißt auf ${language.language} "${currentVocab.foreign}".`;
                    await playText(correctionMessageWithoutAdditionalInfo);
                } catch (error) {
                    console.error('Error playing text:', error);
                }
            }
        }

        // Nächste Vokabel
        setCurrentVocabId(result.nextVocab?.id || null);
      }
    }, [
      checkAnswer, 
      currentVocab, 
      addMessage, 
      correctAnswersCount, 
      confettiTarget, 
      triggerConfetti, 
      updateTrainingVocab, 
      updateScore, 
      language.language, 
      isPlayTextEnabled, 
      playText, 
      playSound, 
      isReverseOrder,
      debug
    ]);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const handleErrorTipClick = async () => {
        setIsErrorTipOpen(true);
        setErrorTipLoading(true);
        try {
            await getErrorTip(currentVocab.id, currentVocab.foreign, currentVocab.german, language.language, isReverseOrder);
        } catch (error) {
            if (debug) console.error("Error fetching error tip:", error);
        } finally {
            setErrorTipLoading(false);
        }
    };

    const closeErrorTip = () => {
        setIsErrorTipOpen(false);
    };

    const handleReverseOrderToggle = () => {
        moveCurrentVocabToEnd();
        setIsReverseOrder(!isReverseOrder);
        setCurrentVocabId(currentVocab?.id || null);
    };

    if (!user || !language) {
        return <Typography>Laden...</Typography>;
    }

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center space-y-2">
                <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                <p className="text-sm text-gray-500 animate-pulse">
                    Vokabeln werden geladen...
                </p>
            </div>
        );
    }

    if (error) {
        if (debug) console.error('Error fetching vocabs:', error);
        return <Typography>Fehler beim Laden der Vokabeln: {error}</Typography>;
    }

    if (activeVocabs.length === 0 && !loading) {
        return <NoVocabsForToday user={user} language={language} />;
    }

    if (progress.remainingVocabs === 0 && !loading) {
        return (
            <Container>
                <VocabProgress progress={progress} />
                <Box width="100%" mt={2}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <IconButton onClick={toggleChat}>
                            {isChatOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                    {isChatOpen && (
                        <Box mt={1}>
                            <ChatUI 
                                messages={messages}
                                messagesContainerRef={messagesContainerRef}
                                userName={userName}
                            />
                        </Box>
                    )}
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Box mt={4} display="flex" flexDirection="column" alignItems="center">
                <Typography variant="body1" align="center" gutterBottom>
                    Fortschritt: {progress.correctAnswers} von {activeVocabs.length} Vokabeln
                </Typography>
                
                <Box width="100%" mb={2}>
                    <LinearProgress 
                        variant="determinate" 
                        value={(progress.correctAnswers / activeVocabs.length) * 100} 
                    />
                </Box>
                
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Tooltip title={isReverseOrder ? `Stattdessen Deutsch zu ${language.language}` : `Stattdessen ${language.language} zu Deutsch`}>
                        <IconButton 
                            color="primary" 
                            onClick={handleReverseOrderToggle}
                        >
                            <SwapHorizIcon />
                        </IconButton>
                    </Tooltip>
                    <Box display="flex" alignItems="center" gap={2}>
                        <TagFilter 
                            vocabs={vocabs} 
                            onFilterChange={setTagFilters}
                        />
                        <Select
                            value={vocabLimit}
                            onChange={handleVocabLimitChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            size="small"
                        >
                            <MenuItem value="all">Alle Vokabeln</MenuItem>
                            <MenuItem value="5">5 Vokabeln</MenuItem>
                            <MenuItem value="10">10 Vokabeln</MenuItem>
                            <MenuItem value="15">15 Vokabeln</MenuItem>
                            <MenuItem value="20">20 Vokabeln</MenuItem>
                            <MenuItem value="25">25 Vokabeln</MenuItem>
                            <MenuItem value="30">30 Vokabeln</MenuItem>
                        </Select>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <VocabDisplay 
                      currentVocab={currentVocab}
                      isReverseOrder={isReverseOrder}
                      hasError={hasError}
                      onErrorTipClick={handleErrorTipClick}
                      language={language}
                    />
                </Box>
                
                <FormControlLabel
                    control={<Switch checked={isPlayTextEnabled} onChange={handleSwitchChange} />}
                    label={isPlayTextEnabled ? <VolumeUpIcon /> : <VolumeOffIcon />}
                />
                
                <VocabInput onSubmit={handleInputSubmit} />
                
                {scoreAnimation && (
                    <Box
                        position="fixed"
                        top="50%"
                        left="50%"
                        sx={{
                            transform: 'translate(-50%, -50%)',
                            animation: 'flyAway 2s forwards',
                            '@keyframes flyAway': {
                                '0%': { opacity: 1, transform: 'translate(-50%, -50%)' },
                                '100%': { opacity: 0, transform: 'translate(-50%, -200%)' }
                            }
                        }}
                    >
                        <Typography variant="h2" color="primary">{scoreAnimation}</Typography>
                    </Box>
                )}

                {errorAnimation && (
                    <Box
                        position="fixed"
                        top="50%"
                        left="50%"
                        sx={{
                            transform: 'translate(-50%, -50%)',
                            animation: 'flyAway 2s forwards',
                            '@keyframes flyAway': {
                                '0%': { opacity: 1, transform: 'translate(-50%, -50%)' },
                                '100%': { opacity: 0, transform: 'translate(-50%, -200%)' }
                            }
                        }}
                    >
                        <Typography variant="h2" color="error" display="flex" alignItems="center">
                            <CloseIcon fontSize="inherit" style={{ marginRight: '8px' }} />
                        </Typography>
                    </Box>
                )}
                
                <Box width="100%" mt={2}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <IconButton onClick={toggleChat}>
                            {isChatOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                    {isChatOpen && (
                        <Box mt={1}>
                            <ChatUI 
                                messages={messages}
                                messagesContainerRef={messagesContainerRef}
                                userName={userName}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Dialog open={isErrorTipOpen} onClose={closeErrorTip}>
                <DialogTitle>Lern-Tipp</DialogTitle>
                <DialogContent>
                    {errorTipLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DialogContentText>
                            {tip || "Kein Tipp verfügbar."}
                        </DialogContentText>
                    )}
                </DialogContent>
            </Dialog>
        </Container>
    );
}
