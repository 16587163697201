import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { auth } from '../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useLogout } from '../hooks/useLogout';
import ScoreBox from '../components/ScoreBox';
import { useCheckScoreDate } from '../hooks/useCheckScoreDate';
import {
  setCurrentUser,
  selectIsPremium,
  selectSubscriptionStatus,
  fetchSubscriptionStatus
} from '../redux/userSlice';

export default function Navbar({ navigateTo }) {
  const { logout } = useLogout();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [firebaseUser] = useAuthState(auth);
  const isPremium = useSelector(selectIsPremium);
  const subscriptionStatus = useSelector(selectSubscriptionStatus);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useCheckScoreDate(currentUser?.uid);

  useEffect(() => {
    if (firebaseUser && currentUser?.uid) {
      dispatch(fetchSubscriptionStatus());
    }
  }, [firebaseUser, currentUser?.uid, dispatch]);

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(setCurrentUser(null));
      navigateTo('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileClick = () => {
    if (currentUser && currentUser.emailVerified) {
      navigateTo('/profile');
    } else {
      navigateTo('/login');
    }
    handleClose();
  };
  const handleHomeClick = () => {
    if (currentUser && !currentUser.emailVerified) {
      navigateTo('/login');
    } else {
      navigateTo('/');
    }
  };
  const handleUpgradeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigateTo('/subscription');
    handleClose();
  };

  /** Premium-Anzeige: auf kleinen Displays als Icon, auf großen Displays als Button/Text */
  const renderPremium = () => {
    // Status "pending"
    if (subscriptionStatus === 'pending') {
      return (
        <IconButton
          onClick={(e) => e.stopPropagation()}
          color="warning"
          title="Premium-Anfrage läuft"
        >
          <WorkspacePremiumIcon />
        </IconButton>
      );
    }
    // Premium aktiv
    if (isPremium) {
      return (
        <IconButton
          onClick={(e) => e.stopPropagation()}
          color="inherit"
          title="Du bist Premium!"
          sx={{ color: 'gold' }}
        >
          <WorkspacePremiumIcon />
        </IconButton>
      );
    }
    // Noch kein Premium => Upgrade anbieten
    return (
      <IconButton
        onClick={handleUpgradeClick}
        color="inherit"
        title="Jetzt Premium werden"
      >
        <StarIcon />
      </IconButton>
    );
  };

  const menuItems = currentUser?.emailVerified
    ? [
        <MenuItem key="profile" onClick={handleProfileClick}>
          Dein Profil
        </MenuItem>,
        !isPremium && (
          <MenuItem key="upgrade" onClick={handleUpgradeClick}>
            <StarIcon sx={{ mr: 1 }} />
            Zu Premium upgraden
          </MenuItem>
        ),
        <MenuItem key="logout" onClick={handleLogout}>
          Abmelden
        </MenuItem>
      ].filter(Boolean)
    : [
        <MenuItem key="verify" onClick={() => navigateTo('/login')}>
          E-Mail bestätigen
        </MenuItem>
      ];

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
      }}
    >
      <Container maxWidth="lg">
        {/*
          Wichtig für "alles in einer Reihe":
            flexWrap: 'nowrap'
            justifyContent: 'space-between'
        */}
        <Toolbar
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {/* Logo-Bereich */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            onClick={handleHomeClick}
          >
            {/* Logo auf kleinen Screens etwas kleiner */}
            <img
              src={`${process.env.PUBLIC_URL}/${encodeURIComponent('BrainWizzr-Logo.svg')}`}
              alt="BrainWizzr Logo"
              style={{
                height: isMobile ? '32px' : '48px',
                width: 'auto',
                marginRight: '10px'
              }}
            />
            {/* Text: "BrainWizzr" nur, wenn Platz genug (optional) */}
            {!isMobile && (
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 600,
                  fontSize: '1.5rem',
                  color: 'inherit',
                  textShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
              >
                BrainWizzr
              </Typography>
            )}
          </Box>

          {/* Rechts ausgerichtete Elemente */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'nowrap'
            }}
          >
            {location.pathname !== '/' && (
              <Button
                color="inherit"
                onClick={handleHomeClick}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)'
                  }
                }}
              >
                Start
              </Button>
            )}

            {/* ScoreBox: Punkteanzeige (immer in einer Zeile) */}
            {currentUser && currentUser.emailVerified && <ScoreBox />}

            {/* Premium Icon/Upgrade */}
            {currentUser && currentUser.emailVerified && (
              <Box onClick={(e) => e.stopPropagation()}>{renderPremium()}</Box>
            )}

            {/* Anmelden / Registrieren (falls nicht eingeloggt) */}
            {!currentUser && (
              <>
                <Button
                  color="inherit"
                  onClick={() => navigateTo('/login')}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,0.1)'
                    }
                  }}
                >
                  Anmelden
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => navigateTo('/signup')}
                  sx={{
                    borderColor: 'rgba(255,255,255,0.5)',
                    '&:hover': {
                      borderColor: 'rgba(255,255,255,0.8)',
                      backgroundColor: 'rgba(255,255,255,0.1)'
                    }
                  }}
                >
                  Registrieren
                </Button>
              </>
            )}

            {/* Account-Icon + Menü (wenn eingeloggt) */}
            {currentUser && (
              <>
                <IconButton
                  color="inherit"
                  onClick={handleMenu}
                  aria-label="Dein Konto"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,0.1)'
                    }
                  }}
                >
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'account-menu'
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      borderRadius: '8px',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  {menuItems}
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
