// src/hooks/useOpenAiTextToSpeech.js
import { useCallback, useState, useRef } from 'react';
import openai from './openai';

/**
 * Optimierte Variante, die nicht jeden Chunk in den State schreibt.
 * Stattdessen werden alle Chunks gesammelt und am Ende abgespielt.
 */
function useOpenAiTextToSpeech() {
  const [isStreaming, setIsStreaming] = useState(false);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const audioElementRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const pendingBuffersRef = useRef([]);
  const currentRequestRef = useRef(null);

  const createAudioContext = useCallback(() => {
    if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    return audioContextRef.current;
  }, []);

  const stopCurrentAudio = useCallback(() => {
    if (sourceNodeRef.current) {
      sourceNodeRef.current.stop();
      sourceNodeRef.current.disconnect();
      sourceNodeRef.current = null;
    }
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.src = '';
    }
    if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
      mediaSourceRef.current.endOfStream();
    }
    setIsStreaming(false);
    pendingBuffersRef.current = [];
  }, []);

  const ensureAudioContextReady = useCallback(async (audioContext) => {
    if (audioContext.state === 'suspended') {
      await audioContext.resume();
    }
    return new Promise((resolve) => {
      if (audioContext.state === 'running') {
        resolve();
      } else {
        const handler = () => {
          if (audioContext.state === 'running') {
            audioContext.removeEventListener('statechange', handler);
            resolve();
          }
        };
        audioContext.addEventListener('statechange', handler);
      }
    });
  }, []);

  const endOfStreamWhenReady = useCallback((mediaSource) => {
    if (mediaSource.readyState === 'open') {
      if (!mediaSource.sourceBuffers.length || !mediaSource.sourceBuffers[0].updating) {
        mediaSource.endOfStream();
      } else {
        setTimeout(() => endOfStreamWhenReady(mediaSource), 100);
      }
    }
  }, []);

  const clearMediaSource = useCallback(() => {
    if (mediaSourceRef.current) {
      if (mediaSourceRef.current.readyState === 'open') {
        try {
          while (mediaSourceRef.current.sourceBuffers.length > 0) {
            mediaSourceRef.current.removeSourceBuffer(mediaSourceRef.current.sourceBuffers[0]);
          }
          mediaSourceRef.current.endOfStream();
        } catch (error) {
          console.error('Error clearing media source:', error);
        }
      }
      mediaSourceRef.current = null;
    }
    sourceBufferRef.current = null;
  }, []);

  const setupMediaSource = useCallback(() => {
    return new Promise((resolve) => {
      mediaSourceRef.current = new MediaSource();
      audioElementRef.current = new Audio();
      audioElementRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      const onSourceOpen = () => {
        if (!mediaSourceRef.current.sourceBuffers.length) {
          sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');
        }
        resolve();
      };

      mediaSourceRef.current.addEventListener('sourceopen', onSourceOpen, { once: true });
    });
  }, []);

  /**
   * Hauptfunktion zum Abspielen eines Textes via TTS:
   * 1) Vorher laufende Streams werden abgebrochen
   * 2) Wir sammeln ALLE Chunks in pendingBuffersRef
   * 3) Erst am Ende wird das Audio abgespielt, um Re-Render zu minimieren
   */
  const playText = useCallback(async (text) => {
    // Falls noch ein anderer Request läuft -> abbrechen
    if (currentRequestRef.current) {
      currentRequestRef.current.abort();
    }

    stopCurrentAudio();
    clearMediaSource();
    setIsStreaming(true);

    const controller = new AbortController();
    currentRequestRef.current = controller;

    try {
      const audioContext = createAudioContext();
      await ensureAudioContextReady(audioContext);
      await setupMediaSource();

      const response = await openai.audio.speech.create({
        model: "tts-1-hd",
        voice: "fable",
        response_format: "mp3",
        input: text,
        signal: controller.signal,
      });

      const reader = response.body.getReader();
      const allChunks = [];

      // Wir sammeln alle Chunks in Memory
      while (true) {
        const { done, value } = await reader.read();
        if (done || controller.signal.aborted) break;
        allChunks.push(value);
      }

      // Wenn abgebrochen -> Cleanup
      if (controller.signal.aborted) {
        throw new Error('TTS-Request abgebrochen');
      }

      // Alle Chunks jetzt verarbeiten
      if (!mediaSourceRef.current || !sourceBufferRef.current) {
        throw new Error('MediaSource nicht initialisiert');
      }

      // Ein einzelner großer Buffer
      const totalLength = allChunks.reduce((sum, chunk) => sum + chunk.byteLength, 0);
      const mergedArray = new Uint8Array(totalLength);
      let offset = 0;
      for (const chunk of allChunks) {
        mergedArray.set(new Uint8Array(chunk), offset);
        offset += chunk.byteLength;
      }

      // Append an SourceBuffer
      sourceBufferRef.current.addEventListener('updateend', () => {
        endOfStreamWhenReady(mediaSourceRef.current);
      }, { once: true });
      sourceBufferRef.current.appendBuffer(mergedArray);

      // Abspielen, sobald der Browser das Audio parsen kann
      audioElementRef.current.oncanplay = () => {
        if (!controller.signal.aborted) {
          audioElementRef.current.play().catch((e) => {
            console.error('Fehler beim Abspielen:', e);
          });
        }
      };

      // Am Ende -> Cleanup
      audioElementRef.current.onended = () => {
        setIsStreaming(false);
        clearMediaSource();
      };
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('TTS Anfrage abgebrochen');
      } else {
        console.error('Fehler in playText:', err);
        setIsStreaming(false);
        stopCurrentAudio();
        clearMediaSource();
      }
      throw err;
    } finally {
      currentRequestRef.current = null;
    }
  }, [
    createAudioContext,
    ensureAudioContextReady,
    stopCurrentAudio,
    clearMediaSource,
    setupMediaSource,
    endOfStreamWhenReady
  ]);

  return { playText, isStreaming };
}

export default useOpenAiTextToSpeech;
