import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/config';
import VocabInput from '../components/VocabInput';
import VocabList from '../components/VocabList';
import LanguageManager from '../components/LanguageManager';
import TrainingPage from '../components/TrainingPage';
import GetStarted from '../components/GetStarted';
import { CurrentUserProvider } from '../contexts/CurrentUserContext';
import { TrainingProvider } from '../contexts/TrainingsContext';
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LearningPage from '../components/LearningPage';
import ConversationTraining from '../components/ConversationTraining';
import GrammarTraining from '../components/GrammarTraining';
import { useRecording } from '../contexts/RecordingContext';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReplayIcon from '@mui/icons-material/Replay';
import MicIcon from '@mui/icons-material/Mic';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector, useDispatch } from 'react-redux';
import { useScoreUpdate } from '../hooks/useScoreUpdate';
import { setCurrentUser } from '../redux/userSlice';

// Neu für Meta-Title/-Description und strukturierte Daten:
import { Helmet } from 'react-helmet';

// ----------------------------------
//  U N A U T H E N T I C A T E D   V I E W
// ----------------------------------
function UnauthenticatedView() {
  // FeatureCard bleibt als Wiederverwendung für einzelne Vorteile
  function FeatureCard({ title, description }) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </CardContent>
      </Card>
    );
  }

  // Mini-Komponente fürs FAQ
  function FAQItem({ question, answer }) {
    return (
      <Box mb={3}>
        <Typography variant="h6" component="h4" gutterBottom>
          {question}
        </Typography>
        <Typography>{answer}</Typography>
      </Box>
    );
  }

  // Mini-Komponente für Testimonials (Social Proof)
  function Testimonial({ quote, author }) {
    return (
      <Box mb={3}>
        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
          „{quote}“
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: 'right', marginTop: '4px' }}
        >
          – {author}
        </Typography>
      </Box>
    );
  }

  // FAQ als strukturierte Daten (JSON-LD) für SEO und LLM-Modelle
  const faqStructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Ist BrainWizzr komplett kostenlos?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Du kannst jederzeit unseren kostenlosen Basiszugang nutzen. Für zusätzliche KI-Features und unbegrenzte Vokabeln gibt es unsere Premium-Option."
        }
      },
      {
        "@type": "Question",
        "name": "Benötige ich eine bestimmte Hardware?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Nein, BrainWizzr läuft als Web-App in jedem modernen Browser – egal ob Desktop, Tablet oder Smartphone."
        }
      },
      {
        "@type": "Question",
        "name": "Funktioniert die App auch offline?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "In der Premium-Version bieten wir einen Offline-Modus, damit du auch unterwegs lernen kannst."
        }
      },
      {
        "@type": "Question",
        "name": "Kann ich meine eigenen Lernsammlungen weiterverkaufen?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ja, du kannst eigene Vokabel- oder Themen-Sammlungen erstellen und diese über BrainWizzr an andere Nutzer verkaufen."
        }
      },
      {
        "@type": "Question",
        "name": "Wie unterscheidet sich BrainWizzr von anderen Sprach-Apps?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Wir kombinieren intelligente Wiederholungstechniken, KI-gestütztes Feedback und flexible Anpassung an verschiedene Zielgruppen – alles in einer ansprechenden Web-App."
        }
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>
          KI-Sprachlernplattform BrainWizzr – Englisch, Deutsch und mehr lernen
        </title>
        <meta
          name="description"
          content="BrainWizzr ist deine KI-Sprachlernplattform für Schüler, Eltern, Studierende und all jene, die Deutsch als Fremdsprache lernen. Nutze Vokabeltrainer, Gamification und personalisierte Lernmodi für Deutsch als Fremdsprache, Englisch und weitere Sprachen."
        />
        {/* JSON-LD für FAQ */}
        <script type="application/ld+json">
          {JSON.stringify(faqStructuredData)}
        </script>
      </Helmet>

      <Container maxWidth="md" sx={{ py: 4 }}>
        {/* Haupt-Headline als H1 */}
        <Typography
          variant="h4"
          component="h1"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold', mb: 4 }}
        >
          Revolutioniere dein Sprachenlernen mit KI-Unterstützung!
        </Typography>

        {/* Kurze Einführung zu USP und Keywords */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Deine all-in-one Sprach-App für Deutsch, Englisch und mehr
          </Typography>
          <Typography paragraph>
            Mit BrainWizzr lernst du Sprachen so einfach und motivierend wie nie zuvor. Ob <strong>Deutsch als Fremdsprache</strong>, <strong>Englisch für Studierende</strong> oder <strong>Sprach-App für Kinder</strong> – unser KI-gestützter Vokabeltrainer passt sich deinem Lerntempo an und hält dich mit spielerischer Gamification stets am Ball.
          </Typography>
        </Box>

        {/* CTA (1. Platzierung) */}
        <Box textAlign="center" mb={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/signup"
            sx={{ px: 4 }}
          >
            Jetzt kostenlos starten!
          </Button>
        </Box>

        {/* Problem & Lösung */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Das Problem beim Sprachenlernen
          </Typography>
          <Typography paragraph>
            Viele Lernende kämpfen mit unübersichtlichen Vokabellisten, fehlender Motivation und mangelndem System, um Wörter langfristig zu behalten. Das ständige Wiederholen wird schnell monoton, und ein durchdachtes Konzept fehlt häufig.
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            Unsere Lösung: BrainWizzr
          </Typography>
          <Typography paragraph>
            BrainWizzr ist deine <strong>KI-gestützte Sprachlernplattform</strong>, die dich mit <strong>Spaced Repetition</strong>, <strong>interaktiven Übungen</strong> und individuellen Lernplänen unterstützt. Dank <em>Gamification</em> bleibt das Lernen spannend, und du erzielst schneller sichtbare Fortschritte.
          </Typography>
        </Box>

        {/* So funktioniert es */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            So funktioniert BrainWizzr
          </Typography>
          <Typography paragraph>
            1. Erstelle deine erste Lernsprache oder dein gewünschtes Fachthema (z.B. „Englisch für die Uni“ oder „Deutsch für den Alltag“).<br />
            2. Füge deine eigenen Vokabeln hinzu oder nutze unsere vorgefertigten Sammlungen im <strong>„Vokabeltrainer online“</strong>.<br />
            3. Lerne mit unserem <strong>KI-Spaced-Repetition-Algorithmus</strong>, der dir die Vokabeln in optimalen Abständen erneut präsentiert.<br />
            4. Profitiere von KI-Hinweisen für Aussprache und Grammatik, um kontinuierlich Feedback zu erhalten.
          </Typography>
        </Box>

        {/* Bestehende Features in FeatureCards */}
        <Box mb={5}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Unbegrenzte Sprach- & Themenauswahl"
                description="Passe BrainWizzr an deine Interessen und Ziele an – ideal für Schüler, Studierende und alle, die Deutsch als Fremdsprache lernen."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Einfaches Vokabelmanagement"
                description="Importiere Wörter per Copy/Paste, CSV-Upload oder nutze unsere fertigen Wortlisten."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="KI-Spaced-Repetition"
                description="Behalte Vokabeln langfristig im Gedächtnis dank intelligenter Wiederholungsintervalle."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Personalisierte Lernmodi"
                description="Erhalte Grammatik-Hinweise und Übungen passend zu deinem aktuellen Niveau."
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FeatureCard
                title="Gamification & Challenges"
                description="Sammle Punkte, baue Daily Streaks auf und tritt gegen Freunde oder andere Lernende an."
              />
            </Grid>
          </Grid>
        </Box>

        {/* Zielgruppen – neu in einzelne Segmente aufgeteilt */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Für wen ist BrainWizzr gemacht?
          </Typography>

          <Typography variant="h6" component="h3" sx={{ mt: 3 }}>
            Für Schüler (und Eltern)
          </Typography>
          <Typography paragraph>
            Jüngere Lernende profitieren von einer spielerischen Umgebung: Mit Challenges, Punkten und täglichen Lernzielen bleiben Kinder und Jugendliche motiviert. Eltern gewinnen Vertrauen durch pädagogisch fundierte Inhalte, DSGVO-konforme Datensicherheit und Fortschrittsberichte.
          </Typography>

          <Typography variant="h6" component="h3" sx={{ mt: 3 }}>
            Für Studierende
          </Typography>
          <Typography paragraph>
            Brauchst du effektiv Vokabeln für dein Auslandssemester oder eine wichtige Prüfung (TOEFL, IELTS, TestDaF)? BrainWizzr passt sich deinem Tagesablauf an und bietet dir personalisierte Übungen – ideal, um deine Sprachkenntnisse neben Vorlesungen und Klausurvorbereitungen zu vertiefen.
          </Typography>

          <Typography variant="h6" component="h3" sx={{ mt: 3 }}>
            Für Deutsch als Fremdsprache Lernende
          </Typography>
          <Typography paragraph>
            Ob du Deutsch als Fremdsprache lernst oder Unterstützung beim Erlernen der Alltagssprache benötigst – unsere KI-Module helfen dir bei typischen Alltagssituationen wie Behördengängen, Arztbesuchen oder Bewerbungsgesprächen. Dank individualisierter Einstiegsniveaus erzielen auch Anfänger schnell erste Erfolge.
          </Typography>

          <Typography variant="h6" component="h3" sx={{ mt: 3 }}>
            Für Berufstätige und Quereinsteiger
          </Typography>
          <Typography paragraph>
            Plane deine Lernzeit flexibel um deinen Job herum und verbessere dein Fachvokabular für Karriere und Weiterbildung. Ob du dich auf internationale Kundenkontakte vorbereitest oder einfach Small Talk auf Englisch verbessern möchtest – BrainWizzr unterstützt dich dabei.
          </Typography>
        </Box>

        {/* Konkurrenz & USPs */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Was macht BrainWizzr einzigartig?
          </Typography>
          <Typography paragraph>
            Viele kennen Duolingo, Babbel oder Memrise – und diese Tools sind sicherlich erfolgreich. Doch wir haben einige Vorteile:
          </Typography>
          <ul>
            <li>
              <strong>Unbegrenzte Sprachauswahl:</strong> Anders als Babbel bieten wir nicht nur 15, sondern beliebig viele Sprachen.
            </li>
            <li>
              <strong>KI-gestützter Vokabeltrainer:</strong> Statt statischer Karten passt unser System die Abfrage per Spaced Repetition laufend an deinen Fortschritt an.
            </li>
            <li>
              <strong>Konversationsorientierte KI:</strong> Übe echte Dialoge dank KI – ein Feature, das klassische Apps häufig nicht bieten.
            </li>
            <li>
              <strong>Freemium-Modell:</strong> Wir sind in der Basis-Version kostenlos und ermöglichen so jedem einen schnellen Einstieg – ideal für Schüler, Studierende und all jene, die Deutsch als Fremdsprache lernen.
            </li>
          </ul>
        </Box>

        {/* Social Proof / Testimonials */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Das sagen unsere Nutzer
          </Typography>
          <Testimonial
            quote="Dank BrainWizzr kann ich endlich konsequent Vokabeln lernen, ohne mich zu langweilen."
            author="Anna, 23 (Studentin)"
          />
          <Testimonial
            quote="Die KI-Feedbacks sind wirklich hilfreich, vor allem bei der Aussprache!"
            author="Markus, 32 (Berufstätig)"
          />
        </Box>

        {/* FAQ */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Häufig gestellte Fragen (FAQ)
          </Typography>
          <FAQItem
            question="Ist BrainWizzr komplett kostenlos?"
            answer="Du kannst jederzeit unseren kostenlosen Basiszugang nutzen. Für zusätzliche KI-Features und unbegrenzte Vokabeln gibt es unsere Premium-Option."
          />
          <FAQItem
            question="Benötige ich eine bestimmte Hardware?"
            answer="Nein, BrainWizzr läuft als Web-App in jedem modernen Browser – egal ob Desktop, Tablet oder Smartphone."
          />
          <FAQItem
            question="Wie hilft die App neurodivergenten Menschen?"
            answer="Die App nutzt Gamification zur Motivation und ermöglicht es, die Anzahl der zu lernenden Vokabeln pro Block individuell anzupassen. Manche bevorzugen 10er-Blöcke, während andere sich mit 3er- oder 5er-Blöcken wohler fühlen, um nicht überfordert zu werden."
          />
          <FAQItem
            question="Wie unterscheidet sich BrainWizzr von anderen Sprach-Apps?"
            answer="Wir kombinieren intelligente Wiederholungstechniken, KI-gestütztes Feedback und flexible Anpassung an verschiedene Zielgruppen – alles in einer ansprechenden Web-App."
          />
        </Box>

        {/* Vertrauen & Sicherheit */}
        <Box mb={5}>
          <Typography variant="h5" component="h2" gutterBottom>
            Vertrauen & Sicherheit
          </Typography>
          <Typography paragraph>
            Wir legen Wert auf Datenschutz und Transparenz. BrainWizzr ist DSGVO-konform, und alle persönlichen Daten werden sicher verschlüsselt übertragen. Unser Impressum und detaillierte Informationen zum Thema Datenschutz findest du jederzeit über das Hauptmenü. So kannst du unbesorgt lernen und dich auf das Wesentliche konzentrieren.
          </Typography>
        </Box>

        {/* CTA (2. Platzierung) */}
        <Box textAlign="center" mb={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/signup"
            sx={{ px: 4 }}
          >
            Jetzt kostenlos starten!
          </Button>
        </Box>
      </Container>
    </>
  );
}

// ----------------------------------
//  H O M E   C O M P O N E N T
// ----------------------------------
export default function Home() {
  const [user, setUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const { isRecording, setIsRecording } = useRecording();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const language = useSelector((state) => state.language);
  const isSubjectTopic = language.isSubjectTopic;
  const { checkAndUpdateScore } = useScoreUpdate(user?.uid);
  const dispatch = useDispatch();

  const mediaStreamRef = useRef(null);
  const recorderRef = useRef(null);

  const startRecording = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaStreamRef.current = stream;
        const newRecorder = new MediaRecorder(stream);
        recorderRef.current = newRecorder;
        newRecorder.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error('Error accessing the microphone', error);
      });
  }, [setIsRecording]);

  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stop();
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
  }, [setIsRecording]);

  useEffect(() => {
    const shouldRecord = (tabValue === 3 || tabValue === 4) && !isSubjectTopic;
    if (shouldRecord && !isRecording) {
      startRecording();
    } else if (!shouldRecord && isRecording) {
      stopRecording();
    }
    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, [tabValue, isRecording, startRecording, stopRecording, isSubjectTopic]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isRecording) {
        stopRecording();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRecording, stopRecording]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const serializableUser = {
          uid: currentUser.uid,
          email: currentUser.email,
          emailVerified: currentUser.emailVerified,
          displayName: currentUser.displayName,
          phoneNumber: currentUser.phoneNumber,
          photoURL: currentUser.photoURL,
        };
        dispatch(setCurrentUser(serializableUser));
      } else {
        dispatch(setCurrentUser(null));
      }
      if (currentUser && currentUser.emailVerified) {
        checkAndUpdateScore();
      }
    });
    return () => unsubscribe();
  }, [checkAndUpdateScore, dispatch]);

  useEffect(() => {
    if (isSubjectTopic && tabValue > 3) {
      setTabValue(3);
    }
  }, [isSubjectTopic, tabValue]);

  const getValidTabValue = useCallback(() => {
    const maxTabIndex = isSubjectTopic ? 3 : 5;
    return Math.min(tabValue, maxTabIndex);
  }, [isSubjectTopic, tabValue]);

  const handleAccordionChange = (event, isExpanded) => {
    setIsAccordionExpanded(isExpanded);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    checkAndUpdateScore();
  };

  const handleMenuMouseEnter = () => {
    setIsMenuExpanded(true);
  };

  const handleMenuMouseLeave = () => {
    setIsMenuExpanded(false);
  };

  const CollapsibleTab = ({ icon, label, ...props }) => (
    <Tab
      icon={icon}
      label={isMenuExpanded ? label : ''}
      sx={{
        minWidth: isMenuExpanded ? 'auto' : '56px',
        transition: 'all 0.3s',
      }}
      {...props}
    />
  );

  const renderTabs = () => {
    const tabs = [
      <CollapsibleTab
        key={0}
        icon={<SettingsIcon />}
        label="Inhaltsverwaltung"
      />,
      <CollapsibleTab key={1} icon={<MenuBookIcon />} label="Lernen" />,
      <CollapsibleTab key={2} icon={<ReplayIcon />} label="Wiederholen" />,
    ];
    if (!isSubjectTopic) {
      tabs.push(
        <CollapsibleTab key={3} icon={<MicIcon />} label="Sprachübung" />,
        <CollapsibleTab key={4} icon={<InfoIcon />} label="Grammatik Erklärung" />
      );
    }
    return tabs;
  };

  const renderContent = () => {
    if (!user || !user.emailVerified) {
      return <UnauthenticatedView />;
    }
    // Check if a language is selected
    if (!language.language) {
      return <GetStarted activeTab={tabValue} />;
    }
    const validTabValue = getValidTabValue();
    switch (validTabValue) {
      case 0:
        return (
          <>
            <Accordion
              expanded={isAccordionExpanded}
              onChange={handleAccordionChange}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="vocab-input-content"
                id="vocab-input-header"
              >
                <Typography>Vokabeln hinzufügen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VocabInput />
              </AccordionDetails>
            </Accordion>
            <VocabList />
          </>
        );
      case 1:
        return <LearningPage />;
      case 2:
        return (
          <TrainingProvider>
            {user && user.displayName && (
              <TrainingPage userName={user.displayName} />
            )}
          </TrainingProvider>
        );
      case 3:
        return (
          <TrainingProvider>
            {user && user.displayName && (
              <ConversationTraining userName={user.displayName} />
            )}
          </TrainingProvider>
        );
      case 4:
        return (
          <TrainingProvider>
            {user && user.displayName && (
              <GrammarTraining userName={user.displayName} />
            )}
          </TrainingProvider>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <CurrentUserProvider>
        {user && user.emailVerified ? (
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Paper
                elevation={3}
                style={{ padding: '20px', margin: '20px 0' }}
              >
                <Typography variant="h6">Hallo, {user.displayName}!</Typography>
                <Box
                  onMouseEnter={handleMenuMouseEnter}
                  onMouseLeave={handleMenuMouseLeave}
                  sx={{
                    transition: 'all 0.3s',
                    width: isMenuExpanded ? '100%' : 'auto',
                  }}
                >
                  <Tabs
                    value={getValidTabValue()}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={isMenuExpanded ? 'auto' : false}
                    allowScrollButtonsMobile
                    aria-label="vocab tabs"
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        justifyContent: isMenuExpanded ? 'flex-start' : 'center',
                      },
                    }}
                  >
                    {renderTabs()}
                  </Tabs>
                </Box>
                {renderContent()}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                elevation={3}
                style={{ padding: '20px', margin: '20px 0' }}
              >
                <Box mt={2}>
                  <LanguageManager />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          renderContent()
        )}
      </CurrentUserProvider>
    </Container>
  );
}
