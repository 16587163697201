// src/hooks/useScoreUpdate.js
import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Verhindert wiederholte Live-Subscriptions bei jedem Aufruf
 * und reduziert damit sowohl CPU- als auch Speicherauslastung.
 * 
 * Anwendungsbeispiel:
 * const { dayScore, totalScore, checkAndUpdateScore } = useScoreUpdate(user?.uid);
 * useEffect(() => {
 *   if (user) { checkAndUpdateScore(); }
 * }, [user]);
 */
export const useScoreUpdate = (userId) => {
  const [dayScore, setDayScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  /**
   * Prüft, ob ein neuer Tag begonnen hat. Falls ja, wird dayScore zurückgesetzt.
   * Lädt und setzt anschließend dayScore + totalScore ins lokale State.
   */
  const checkAndUpdateScore = useCallback(async () => {
    if (!userId) return;

    try {
      const userStatsRef = doc(db, 'userStats', userId);
      const docSnap = await getDoc(userStatsRef);

      // Dokument existiert noch nicht -> anlegen
      if (!docSnap.exists()) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        await setDoc(userStatsRef, {
          dayDate: today,
          dayScore: 0,
          totalScore: 0,
        });

        setDayScore(0);
        setTotalScore(0);
        return;
      }

      // Dokument existiert bereits
      const data = docSnap.data();
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      let storedDate = null;
      if (data.dayDate) {
        // Firestore Timestamp -> JS-Date
        storedDate = data.dayDate.toDate();
        storedDate.setHours(0, 0, 0, 0);
      }

      // Neuer Tag -> reset dayScore
      if (!storedDate || storedDate < today) {
        await updateDoc(userStatsRef, {
          dayDate: today,
          dayScore: 0,
        });
        setDayScore(0);
      } else {
        setDayScore(data.dayScore || 0);
      }

      setTotalScore(data.totalScore || 0);
    } catch (e) {
      console.error('Fehler in checkAndUpdateScore:', e);
    }
  }, [userId]);

  // Optional: Einmal beim Mounten direkt aufrufen
  useEffect(() => {
    checkAndUpdateScore();
  }, [checkAndUpdateScore]);

  return {
    dayScore,
    totalScore,
    checkAndUpdateScore,
  };
};
