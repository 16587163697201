// src/components/ConversationTraining.js

import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Switch,
  FormControlLabel,
  TextField,
  Button,
  LinearProgress,
  Autocomplete
} from '@mui/material';
import { Mic, StopCircle } from '@mui/icons-material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/config';
import { useVocabList } from '../hooks/useVocabList';
import { useSelector } from 'react-redux';
import { useRealtimeConnection } from '../hooks/useRealtimeConnection';
import { TagFilter } from './TagFilter';

// Hilfsfunktion zur Ermittlung eines ISO-Codes aus dem Sprachstring (wie zuvor)
function getLanguageCode(languageName) {
  const lower = (languageName || '').toLowerCase();
  if (lower.includes('engl')) return 'en';
  if (lower.includes('deut')) return 'de';
  if (lower.includes('fran')) return 'fr';
  if (lower.includes('span')) return 'es';
  if (lower.includes('ital')) return 'it';
  if (lower.includes('portu')) return 'pt';
  return 'en';
}

export default function ConversationTraining() {
  // ---------------------------------------
  // 1) HOOKS & STATES
  // ---------------------------------------
  const [user] = useAuthState(auth);
  const userId = user?.uid ?? null;

  // Sprache aus Redux-Store
  const languageObj = useSelector((state) => state.language);
  const selectedLanguageStr = languageObj?.language || '';

  // Laden der Vokabelliste
  const { vocabPairs, error: vocabError } = useVocabList(userId, languageObj);

  // Filterstates
  const [knownFilter, setKnownFilter] = useState('all');
  const [tagFilters, setTagFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  // Auswahl einer Vokabel (Index + Objekt)
  const [vocabIndex, setVocabIndex] = useState(0);
  const [selectedVocab, setSelectedVocab] = useState(null);

  // Blur-States
  const [isBlurred, setIsBlurred] = useState(true);
  const [isAdditionalInfoBlurred, setIsAdditionalInfoBlurred] = useState(true);

  // Audio an/aus
  const [isPlayTextEnabled, setIsPlayTextEnabled] = useState(true);

  // States für Realtime-Connection
  const {
    isConnected,
    isConnecting,
    error: realtimeError,
    debugInfo, // Wenn du Debug-Infos brauchst
    messages,  // Wenn du Debug-Infos brauchst
    connectRealtime,
    disconnect,
    handleUserMessage,
    remoteAudioRef
  } = useRealtimeConnection();

  // Eingabe an die KI
  const [userInput, setUserInput] = useState('');

  // ---------------------------------------
  // 2) FILTERING
  // ---------------------------------------
  const filteredVocabPairs = useMemo(() => {
    if (!vocabPairs) return [];

    // Zuerst Kopie
    let filtered = [...vocabPairs];

    // knownFilter
    if (knownFilter !== 'all') {
      const target = parseInt(knownFilter, 10);
      if (target === 3) {
        filtered = filtered.filter((v) => v.knownCounter >= 3);
      } else {
        filtered = filtered.filter((v) => v.knownCounter === target);
      }
    }

    // tagFilters
    const hasActiveTagFilters = Object.values(tagFilters).some((valObj) =>
      Object.values(valObj).some((isActive) => isActive)
    );
    if (hasActiveTagFilters) {
      filtered = filtered.filter((vocab) => {
        if (!vocab.attributes) return false;
        return Object.entries(tagFilters).every(([key, values]) => {
          const activeVals = Object.entries(values)
            .filter(([_, isActive]) => isActive)
            .map(([val]) => val);
          if (activeVals.length === 0) return true;
          return vocab.attributes[key] && activeVals.includes(vocab.attributes[key]);
        });
      });
    }

    // Sortiert zurückgeben
    return filtered.sort((a, b) => a.german.localeCompare(b.german));
  }, [vocabPairs, knownFilter, tagFilters]);

  // ---------------------------------------
  // 3) REALTIME-INSTRUKTIONEN
  // ---------------------------------------
  const updateRealtimeInstructions = useCallback(
    (index) => {
      if (!isConnected) return;
      if (!filteredVocabPairs[index]) return;

      const vocab = filteredVocabPairs[index];
      const isoCode = getLanguageCode(selectedLanguageStr);

      const instructions = `
        You are a professional language teacher specialized in ${selectedLanguageStr}.
        Current vocab pair:
          German: "${vocab.german}"
          Foreign: "${vocab.foreign}"
        Please help the user practice...
      `.replace(/\n\s+/g, ' ');

      handleUserMessage(
        '<<<SESSION_UPDATE>>>' +
          JSON.stringify({
            type: 'session.update',
            session: {
              modalities: ['audio', 'text'],
              instructions,
              voice: 'verse',
              temperature: 0.2,
              turn_detection: {
                type: 'server_vad',
                threshold: 0.5,
                prefix_padding_ms: 300,
                silence_duration_ms: 200,
                create_response: true
              },
              input_audio_transcription: {
                model: 'whisper-1',
                language: isoCode
              },
              target_language: isoCode,
              vocabulary: {
                german: vocab.german,
                target: vocab.foreign
              }
            }
          })
      );
    },
    [isConnected, filteredVocabPairs, selectedLanguageStr, handleUserMessage]
  );

  // ---------------------------------------
  // 4) UI-AKTIONEN
  // ---------------------------------------
  function handleSendMessage() {
    if (!userInput.trim()) return;
    handleUserMessage(userInput);
    setUserInput('');
  }

  function handleNextVocab() {
    // Falls keine Einträge, abbrechen
    if (!filteredVocabPairs.length) return;

    let next = vocabIndex + 1;
    if (next >= filteredVocabPairs.length) {
      next = 0;
    }
    setVocabIndex(next);
    setSelectedVocab(filteredVocabPairs[next]);
    setIsBlurred(true);
    setIsAdditionalInfoBlurred(true);
    updateRealtimeInstructions(next);
  }

  // Wenn sich selectedVocab ändert, passe den vocabIndex an
  useEffect(() => {
    if (!selectedVocab) return;
    const idx = filteredVocabPairs.findIndex((v) => v.id === selectedVocab.id);
    if (idx >= 0) {
      setVocabIndex(idx);
    }
  }, [selectedVocab, filteredVocabPairs]);

  // ---------------------------------------
  // 5) Auto-Update instructions bei Verbindungsänderung
  // ---------------------------------------
  useEffect(() => {
    if (isConnected) {
      updateRealtimeInstructions(vocabIndex);
    }
  }, [isConnected, vocabIndex, updateRealtimeInstructions]);

  // ---------------------------------------
  // 6) Reconnect-Logik, wenn Sprache oder Vokabel-ID sich ändert
  // ---------------------------------------
  const prevLanguageRef = useRef(selectedLanguageStr);
  const prevVocabIdRef = useRef(filteredVocabPairs[vocabIndex]?.id || null);

  useEffect(() => {
    if (!isConnected) return;

    const currentLang = selectedLanguageStr;
    const currentVocabId = filteredVocabPairs[vocabIndex]?.id || null;

    const langHasChanged = currentLang !== prevLanguageRef.current;
    const vocabHasChanged = currentVocabId !== prevVocabIdRef.current;

    if (langHasChanged || vocabHasChanged) {
      disconnect();
      const currentVocab = filteredVocabPairs[vocabIndex] || null;
      connectRealtime(currentLang, currentVocab);
    }

    prevLanguageRef.current = currentLang;
    prevVocabIdRef.current = currentVocabId;
  }, [
    isConnected,
    selectedLanguageStr,
    vocabIndex,
    filteredVocabPairs,
    disconnect,
    connectRealtime
  ]);

  // ---------------------------------------
  // 7) Cleanup beim Unmount
  // ---------------------------------------
  useEffect(() => {
    return () => {
      if (isConnected) {
        disconnect();
      }
    };
  }, [isConnected, disconnect]);

  // ---------------------------------------
  // 8) RENDER-LOGIK
  // ---------------------------------------
  let content;

  // A) Nicht eingeloggt
  if (!userId) {
    content = <Typography color="error">Bitte einloggen.</Typography>;

    // B) Sprache noch nicht gesetzt?
  } else if (!selectedLanguageStr) {
    content = <LinearProgress />;

    // C) Fehler beim Laden der Vokabeln
  } else if (vocabError) {
    content = <Typography color="error">Fehler: {vocabError.message}</Typography>;

    // D) Vokabelliste wird noch geladen
  } else if (!vocabPairs) {
    content = <LinearProgress />;

    // E) Gefilterte Liste leer?
  } else if (!filteredVocabPairs || filteredVocabPairs.length === 0) {
    content = (
      <Typography variant="body1" color="text.secondary">
        Keine passenden Vokabeln gefunden.
      </Typography>
    );

    // F) Normalfall: Wir haben Vokabeln
  } else {
    const currentVocab = filteredVocabPairs[vocabIndex]; // Ist jetzt sicher definiert, da length > 0

    content = (
      <Box>
        {/* Realtime-Fehleranzeige (optional) */}
        {realtimeError && (
          <Typography color="error" sx={{ mb: 2 }}>
            Realtime Error: {realtimeError}
          </Typography>
        )}

        {/* Kurze Info: Verbunden / Nicht verbunden */}
        {isConnected ? (
          <Typography variant="body2" color="success.main" sx={{ mb: 1 }}>
            Verbunden
          </Typography>
        ) : (
          <Typography variant="body2" color="error" sx={{ mb: 1 }}>
            Nicht verbunden
          </Typography>
        )}

        {/* Connect / Disconnect */}
        <Box mb={2}>
          {!isConnected ? (
            <Button
              variant="contained"
              onClick={() => connectRealtime(selectedLanguageStr, currentVocab || null)}
              disabled={isConnecting}
              startIcon={<Mic />}
            >
              {isConnecting ? 'Verbinde...' : 'Verbindung starten'}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={disconnect}
              startIcon={<StopCircle />}
            >
              Verbindung trennen
            </Button>
          )}
        </Box>

        {/* Filter UI */}
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <FormControl size="small">
            <InputLabel>knownFilter</InputLabel>
            <Select
              label="knownFilter"
              value={knownFilter}
              onChange={(e) => setKnownFilter(e.target.value)}
            >
              <MenuItem value="all">Alle</MenuItem>
              <MenuItem value="0">Noch nie gewusst</MenuItem>
              <MenuItem value="1">Einmal gewusst</MenuItem>
              <MenuItem value="2">Zweimal gewusst</MenuItem>
              <MenuItem value="3">Dreimal oder öfter</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="outlined"
            size="small"
            onClick={() => setShowFilters(!showFilters)}
          >
            Tags
          </Button>

          <FormControlLabel
            control={
              <Switch
                checked={isPlayTextEnabled}
                onChange={(e) => setIsPlayTextEnabled(e.target.checked)}
              />
            }
            label={isPlayTextEnabled ? 'Laut' : 'Stumm'}
          />
        </Box>

        {showFilters && (
          <Box mb={2}>
            <TagFilter vocabs={vocabPairs} onFilterChange={setTagFilters} />
          </Box>
        )}

        {/* Autocomplete: Ein Feld für Auswahl + Freitext */}
        <Box sx={{ mb: 2 }}>
          <Autocomplete
            value={selectedVocab}
            onChange={(event, newValue) => {
              if (!newValue) {
                setSelectedVocab(null);
                return;
              }
              setSelectedVocab(newValue);
              const idx = filteredVocabPairs.findIndex((v) => v.id === newValue.id);
              if (idx >= 0) {
                setVocabIndex(idx);
                setIsBlurred(true);
                setIsAdditionalInfoBlurred(true);
                updateRealtimeInstructions(idx);
              }
            }}
            options={filteredVocabPairs}
            getOptionLabel={(option) => `${option.german} - ${option.foreign}`}
            renderInput={(params) => (
              <TextField {...params} label="Wort auswählen oder suchen" size="small" />
            )}
          />
        </Box>

        {/* Aktuelle Vokabel-Anzeige */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6">
            Satz in {selectedLanguageStr} mit:
            <strong> {currentVocab.german}</strong>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              cursor: 'pointer',
              filter: isBlurred ? 'blur(4px)' : 'none',
              transition: 'filter 0.3s'
            }}
            onClick={() => setIsBlurred(!isBlurred)}
          >
            {currentVocab.foreign}
          </Typography>
          {currentVocab.additionalInfo && (
            <Typography
              variant="body2"
              onClick={() => setIsAdditionalInfoBlurred(!isAdditionalInfoBlurred)}
              sx={{
                mt: 1,
                cursor: 'pointer',
                filter: isAdditionalInfoBlurred ? 'blur(4px)' : 'none'
              }}
            >
              {currentVocab.additionalInfo}
            </Typography>
          )}
          <Chip
            label={`${currentVocab.knownCounter} mal gewusst`}
            size="small"
            sx={{ mt: 1 }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleNextVocab}>
              Nächste Vokabel
            </Button>
          </Box>
        </Paper>

        {/* Eingabefeld => an KI (nur wenn verbunden) */}
        {isConnected && (
          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <TextField
              fullWidth
              multiline
              maxRows={4}
              placeholder="Dein Satz..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
            <Button
              variant="contained"
              onClick={handleSendMessage}
              disabled={!userInput.trim()}
            >
              Senden
            </Button>
          </Box>
        )}

        {/* DEBUG-Bereich auskommentiert / optional nur in DEV anzeigen
        {process.env.NODE_ENV === 'development' && (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              ICE: {debugInfo.iceConnectionState} | 
              Conn: {debugInfo.connectionState} | 
              DC: {debugInfo.dataChannelState} |
              Audio: {debugInfo.audioPlaying ? 'yes' : 'no'}
            </Typography>

            <Typography variant="subtitle1">Nachrichten:</Typography>
            {messages.map((m, i) => (
              <Paper key={i} sx={{ p: 1, mb: 1 }}>
                <pre style={{ margin: 0 }}>
                  {JSON.stringify(m, null, 2)}
                </pre>
              </Paper>
            ))}
          </>
        )}
        */}

        {/* Audio-Element für den Remote-Stream */}
        <audio
          ref={remoteAudioRef}
          style={{ display: 'none' }}
          autoPlay
          playsInline
          muted={false}
        />
      </Box>
    );
  }

  // ---------------------------------------
  // Container / Return
  // ---------------------------------------
  return (
    <Container maxWidth="md" sx={{ py: 3 }}>
      {content}
    </Container>
  );
}
