import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../firebase/config';
import { doc, onSnapshot } from 'firebase/firestore';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TodayIcon from '@mui/icons-material/Today';

export default function ScoreBox() {
  const currentUser = useSelector((state) => state.user.currentUser);

  const [dayScore, setDayScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  // MediaQuery für kleine Displays:
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!currentUser || !currentUser.emailVerified) {
      return;
    }

    const userStatsRef = doc(db, 'userStats', currentUser.uid);

    const unsubscribe = onSnapshot(userStatsRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setDayScore(data.dayScore ?? 0);
        setTotalScore(data.totalScore ?? 0);
      }
    });

    return () => unsubscribe();
  }, [currentUser]);

  if (!currentUser || !currentUser.emailVerified) return null;

  /**
   * Auf kleinen Displays: nur Punktzahl
   * Auf größeren Displays: "Tag: X" / "Gesamt: X"
   */
  const dayLabel = isMobile ? dayScore : `Tag: ${dayScore}`;
  const totalLabel = isMobile ? totalScore : `Gesamt: ${totalScore}`;

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ mr: 1 }} // Kleiner Margin rechts
    >
      <Chip
        icon={<TodayIcon />}
        label={dayLabel}
        color="secondary"
        size="small"
        sx={{ mr: 1, fontWeight: 'bold' }}
      />
      <Chip
        icon={<EmojiEventsIcon />}
        label={totalLabel}
        color="secondary"
        size="small"
        sx={{ fontWeight: 'bold' }}
      />
    </Box>
  );
}
