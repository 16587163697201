// src/services/usageTrackingService.js
import { db } from '../firebase/config';
import { doc, setDoc, updateDoc, getDoc, increment } from 'firebase/firestore';

/**
 * Vereinfacht und ohne automatische store.subscribe().
 * Start/Stop wird direkt in App.js via useEffect geregelt.
 */
class UsageTrackingService {
  constructor() {
    this.activeMinutes = 0;
    this.interactionsThisInterval = 0;
    this.consecutiveActiveIntervals = 0;
    this.interval = null;
    this.userId = null;

    this.recordInteraction = this.recordInteraction.bind(this);
  }

  /**
   * Wird einmalig aufgerufen, um das Tracking zu starten
   * (z.B. sobald user & user.emailVerified bekannt sind).
   */
  startTracking(userId) {
    if (this.interval) {
      // Läuft schon? -> Nichts tun
      return;
    }
    this.userId = userId;
    // Alle 30s prüfen
    this.interval = setInterval(() => this.checkAndUpdateActiveTime(), 30000);

    // Events für Aktivität
    window.addEventListener('mousemove', this.recordInteraction);
    window.addEventListener('keydown', this.recordInteraction);
    window.addEventListener('touchstart', this.recordInteraction);
    window.addEventListener('touchmove', this.recordInteraction);
    window.addEventListener('touchend', this.recordInteraction);
  }

  /**
   * Stopp-Logik, damit kein Speicherleck entsteht.
   * Prüfe, ob wir überhaupt Event Listener hinzufügen konnten,
   * bevor wir sie entfernen.
   */
  stopTracking() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    // Nur entfernen, wenn wir auch eine Function haben
    if (this.recordInteraction) {
      window.removeEventListener('mousemove', this.recordInteraction);
      window.removeEventListener('keydown', this.recordInteraction);
      window.removeEventListener('touchstart', this.recordInteraction);
      window.removeEventListener('touchmove', this.recordInteraction);
      window.removeEventListener('touchend', this.recordInteraction);
    }
    // Reset interne Variablen
    this.activeMinutes = 0;
    this.interactionsThisInterval = 0;
    this.consecutiveActiveIntervals = 0;
    this.userId = null;
  }

  /**
   * Wird bei Mausbewegung/Tastaturdruck etc. getriggert
   */
  recordInteraction() {
    this.interactionsThisInterval++;
  }

  /**
   * Alle 30s geprüft: Wenn in diesem 30s-Fenster Interaktionen stattfanden,
   * gilt das als 30s "aktiv". Alle 2 Intervalle -> 1 Minute.
   */
  checkAndUpdateActiveTime() {
    if (!this.userId) return;

    // Gab es in diesem 30s-Fenster Interaktionen?
    if (this.interactionsThisInterval > 0) {
      this.consecutiveActiveIntervals++;
      // Nach 2 * 30s -> 1 Minute
      if (this.consecutiveActiveIntervals === 2) {
        this.activeMinutes++;
        this.updateFirestore();
        this.consecutiveActiveIntervals = 0;
      }
    } else {
      this.consecutiveActiveIntervals = 0;
    }
    this.interactionsThisInterval = 0;
  }

  /**
   * Schreibt +1 Minute (oder wie viele sich angesammelt haben) in Firestore
   */
  async updateFirestore() {
    try {
      const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
      const userStatsRef = doc(db, 'userStats', this.userId);
      const dailyStatsRef = doc(userStatsRef, 'dailyStats', date);

      // Übergeordnetes userStats-Dokument anlegen falls nicht vorhanden
      const userStatsSnap = await getDoc(userStatsRef);
      if (!userStatsSnap.exists()) {
        await setDoc(userStatsRef, { createdAt: new Date() });
      }

      // Tägliches Statistik-Dokument
      const dailyStatsSnap = await getDoc(dailyStatsRef);
      if (dailyStatsSnap.exists()) {
        // Nur +1
        await updateDoc(dailyStatsRef, {
          activeMinutes: increment(1),
        });
      } else {
        // Neu anlegen mit activeMinutes=1
        await setDoc(dailyStatsRef, {
          activeMinutes: 1,
        });
      }
    } catch (error) {
      console.error('Error updating usage tracking data:', error);
    }
  }
}

export const usageTrackingService = new UsageTrackingService();
