// src/hooks/useRealtimeConnection.js
import { useRef, useState, useCallback, useEffect } from 'react';

export function useRealtimeConnection() {
  const pcRef = useRef(null);
  const dataChannelRef = useRef(null);
  const remoteAudioRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState({
    iceConnectionState: '',
    connectionState: '',
    dataChannelState: '',
    audioPlaying: false,
    audioLevel: 0,
    lastMessageReceived: ''
  });

  // Hilfsfunktion: Warten auf Ende des ICE-Gathering
  const waitForIceGatheringComplete = (pc) => {
    return new Promise((resolve) => {
      if (pc.iceGatheringState === 'complete') {
        resolve();
      } else {
        function checkState() {
          if (pc.iceGatheringState === 'complete') {
            pc.removeEventListener('icegatheringstatechange', checkState);
            resolve();
          }
        }
        pc.addEventListener('icegatheringstatechange', checkState);
      }
    });
  };

  // Audio-Element-Listener einrichten
  useEffect(() => {
    const audioEl = remoteAudioRef.current;
    if (!audioEl) return;

    const handlePlaying = () => {
      setDebugInfo((prev) => ({ ...prev, audioPlaying: true }));
    };
    audioEl.addEventListener('playing', handlePlaying);

    return () => {
      // Cleanup
      if (audioEl) {
        audioEl.removeEventListener('playing', handlePlaying);
      }
    };
  }, [remoteAudioRef]);

  const disconnect = useCallback(() => {
    if (pcRef.current) {
      pcRef.current.close();
      pcRef.current = null;
    }
    if (dataChannelRef.current) {
      dataChannelRef.current.close();
      dataChannelRef.current = null;
    }
    setIsConnected(false);
    setIsConnecting(false);
    setError(null);
    setDebugInfo((prev) => ({ ...prev, audioPlaying: false }));
  }, []);

  /**
   * Aufbau der Realtime-Verbindung.
   * @param {string} languageParam - Der Name der Zielsprache (z. B. "English", "Französisch", etc.).
   * @param {object|null} vocabularyPair - Das aktuell gewählte Vokabelpaar im Format { german, foreign }.
   */
  const connectRealtime = useCallback(async (languageParam, vocabularyPair) => {
    setIsConnecting(true);
    setError(null);

    try {
      // Beispiel: ephemeralKey / Info vom Backend holen
      const queryParams = new URLSearchParams();
      if (languageParam) queryParams.set('language', languageParam);
      if (vocabularyPair && vocabularyPair.german && vocabularyPair.foreign) {
        queryParams.set('vocabulary', JSON.stringify(vocabularyPair));
      }

      const res = await fetch(`https://brainwizzr-backend-41f7251d9520.herokuapp.com/api/realtime/ephemeral-key?${queryParams.toString()}`);
      const jsonData = await res.json();
      const { client_secret: { value: ephemeralKey } } = jsonData;

      // Erstelle die RTCPeerConnection
      const pc = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }]
      });

      pc.ontrack = (event) => {
        if (event.track.kind === 'audio') {
          if (remoteAudioRef.current) {
            remoteAudioRef.current.srcObject = event.streams[0];
            remoteAudioRef.current
              .play()
              .then(() => {
                console.log('Audio playback started');
              })
              .catch((err) => {
                console.warn('Audio playback blocked or error:', err);
              });
          }
        }
      };

      pc.oniceconnectionstatechange = () => {
        setDebugInfo((prev) => ({
          ...prev,
          iceConnectionState: pc.iceGatheringState
        }));
      };

      pc.onconnectionstatechange = () => {
        setDebugInfo((prev) => ({
          ...prev,
          connectionState: pc.connectionState
        }));
        if (pc.connectionState === 'connected') {
          setIsConnected(true);
          setIsConnecting(false);
        } else if (pc.connectionState === 'failed') {
          setError('Connection failed');
          disconnect();
        }
      };

      // Erstelle einen DataChannel
      const dc = pc.createDataChannel('oai-events');
      dc.onopen = () => {
        dataChannelRef.current = dc;
        setDebugInfo((prev) => ({ ...prev, dataChannelState: 'open' }));
      };

      dc.onmessage = async (e) => {
        try {
          const msg = JSON.parse(e.data);
          setMessages((prev) => [msg, ...prev]);
          setDebugInfo((prev) => ({
            ...prev,
            lastMessageReceived: new Date().toISOString()
          }));
        } catch (err) {
          console.error('Message handling error:', err);
        }
      };

      // lokales Audio
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 48000,
          echoCancellation: true,
          noiseSuppression: true
        }
      });
      stream.getTracks().forEach((track) => {
        pc.addTrack(track, stream);
      });

      // local offer
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
      await waitForIceGatheringComplete(pc);

      // fetch remote sdp
      const sdpResponse = await fetch(
        'https://api.openai.com/v1/realtime?model=gpt-4o-realtime-preview-2024-12-17',
        {
          method: 'POST',
          body: pc.localDescription.sdp,
          headers: {
            Authorization: `Bearer ${ephemeralKey}`,
            'Content-Type': 'application/sdp'
          }
        }
      );
      if (!sdpResponse.ok) {
        throw new Error(`SDP exchange failed: ${await sdpResponse.text()}`);
      }
      const remoteSdp = await sdpResponse.text();
      await pc.setRemoteDescription({ type: 'answer', sdp: remoteSdp });

      pcRef.current = pc;
    } catch (err) {
      console.error('Connection error:', err);
      setError(err.message);
      setIsConnecting(false);
      disconnect();
    }
  }, [disconnect]);

  const sendMessage = useCallback(
    (msgObj) => {
      if (!dataChannelRef.current || !isConnected) {
        console.warn('DataChannel not ready or not connected');
        return;
      }
      try {
        dataChannelRef.current.send(JSON.stringify(msgObj));
      } catch (err) {
        console.error('Send error:', err);
      }
    },
    [isConnected]
  );

  const handleUserMessage = useCallback((text) => {
    // SESSION_UPDATE vs. normal user message
    if (text.startsWith('<<<SESSION_UPDATE>>>')) {
      const jsonPart = text.replace('<<<SESSION_UPDATE>>>', '');
      try {
        const parsed = JSON.parse(jsonPart);
        sendMessage(parsed);
        return;
      } catch (e) {
        console.error('SESSION_UPDATE JSON invalid:', e);
        return;
      }
    }
    // Normale Nachricht
    sendMessage({
      type: 'conversation.item.create',
      item: {
        role: 'user',
        content: text
      }
    });
    // Extra request
    sendMessage({
      type: 'response.create',
      response: {
        output_modalities: ['audio', 'text'],
        format: 'pcm16',
        sample_rate: 48000,
        stream_to_client: true,
        voice: {
          type: 'builtin',
          id: 'verse'
        },
        audio_format: {
          type: 'pcm16',
          sample_rate: 48000
        }
      }
    });
  }, [sendMessage]);

  useEffect(() => {
    return () => {
      // Komponente unmountet
      disconnect();
    };
  }, [disconnect]);

  return {
    isConnected,
    isConnecting,
    error,
    debugInfo,
    messages,
    connectRealtime,
    disconnect,
    handleUserMessage,
    remoteAudioRef
  };
}
